var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar",attrs:{"data-color":_vm.sidebarItemColor}},[_c('div',{staticClass:"logo"},[_c('div',{staticClass:"brand-column"},[_c('img',{staticClass:"logo",attrs:{"alt":"logo","src":_vm.imgLogo}}),_c('div',{staticClass:"company-header"},[_vm._v(" "+_vm._s(_vm.$store.getters["settings/getMainSettings"].companyName)+" "),_c('br'),_c('small',[_vm._v("Powered by MPM")])])])]),_c('div',{staticClass:"sidebar-wrapper"},[_vm._t("content"),_c('md-list',{staticClass:"no-bg p-15",attrs:{"md-expand-single":""}},[_vm._l((_vm.routes),function(menu){return [(
            menu.meta?.sidebar?.enabled ||
            _vm.getEnabledPlugins.includes(
              menu.meta?.sidebar?.enabled_by_mpm_plugin_id,
            )
          )?[(!_vm.hasSubMenu(menu))?_c('router-link',{key:'menu' + menu.meta?.sidebar?.name ?? menu.path,attrs:{"to":menu.path}},[_c('md-list-item',[(menu.meta?.sidebar?.icon)?_c('md-icon',{staticClass:"c-white icon-box"},[_vm._v(" "+_vm._s(menu.meta.sidebar.icon)+" ")]):_vm._e(),_c('span',{staticClass:"md-list-item-text c-white"},[_vm._v(" "+_vm._s(_vm.$tc("menu." + menu.meta?.sidebar?.name ?? menu.path))+" ")]),(_vm.protectedPages.includes(menu.path))?_c('md-icon',{staticClass:"c-white password-protected-lock-icon"},[_vm._v(" lock ")]):_vm._e()],1)],1):_c('div',{key:'submenu' + menu.meta?.sidebar?.name ?? menu.path},[_c('md-list-item',{attrs:{"md-expand":""}},[(menu.meta?.sidebar?.icon)?_c('md-icon',{staticClass:"c-white icon-box"},[_vm._v(" "+_vm._s(menu.meta.sidebar.icon)+" ")]):_vm._e(),_c('span',{staticClass:"md-list-item-text c-white"},[_vm._v(" "+_vm._s(menu.meta?.sidebar?.name ?? menu.path)+" ")]),_c('md-list',{staticClass:"no-bg",attrs:{"slot":"md-expand"},slot:"md-expand"},_vm._l((menu.children),function(sub){return _c('router-link',{key:sub.path,staticClass:"sub-menu",attrs:{"to":_vm.subMenuUrl(menu.path, sub.path)}},[(
                      sub.meta?.sidebar?.enabled ||
                      _vm.getEnabledPlugins.includes(
                        sub.meta?.sidebar?.enabled_by_mpm_plugin_id,
                      )
                    )?[_c('md-list-item',[_c('span',{staticClass:"md-list-item-text c-white"},[_vm._v(" "+_vm._s(_vm.$tc( "menu.subMenu." + sub.meta?.sidebar?.name ?? sub.path, ))+" ")]),(
                          _vm.protectedPages.includes(
                            _vm.subMenuUrl(menu.path, sub.path),
                          )
                        )?_c('md-icon',{staticClass:"c-white password-protected-lock-icon"},[_vm._v(" lock ")]):_vm._e()],1)]:_vm._e()],2)}),1)],1)],1)]:_vm._e()]})],2)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }